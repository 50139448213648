var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"merchant-add"},[_c('GlobalChunk',{attrs:{"icon":"add","title":"门店优势设置","padding":[32, 0, 95, 42]}},[_c('el-row',[_c('el-col',{attrs:{"span":8}},[_c('el-form',{ref:"dynamicValidateForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.formData,"label-width":"100px"}},[_vm._l((_vm.formData.titles),function(item,index){return _c('el-form-item',{key:item.key,attrs:{"prop":'titles.' + index + '.value',"rules":{
              required: true,
              message: '主标题不能为空',
              trigger: 'blur',
            }}},[_c('span',{attrs:{"slot":"label"},slot:"label"},[_vm._v("主标题"+_vm._s(index * 1 + 1)+"：")]),_c('div',{staticClass:"flex"},[_c('el-input',{attrs:{"placeholder":"请输入主标题"},model:{value:(item.value),callback:function ($$v) {_vm.$set(item, "value", $$v)},expression:"item.value"}}),(index == 0)?_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"primary","size":"small"},on:{"click":_vm.addDomain}},[_vm._v("新增")]):_c('el-button',{staticStyle:{"margin-left":"10px"},attrs:{"type":"danger","size":"small"},on:{"click":function($event){$event.preventDefault();return _vm.removeDomain(item)}}},[_vm._v("删除")])],1)])}),_c('el-form-item',{staticClass:"el_from",attrs:{"label":"详情上传","prop":"infos","rules":{
              required: true,
              message: '详情图片不能为空',
              trigger: 'blur',
            }}},[_c('ElImgUpload',{attrs:{"exist-image":_vm.formData.infos,"isBigImg":true,"place-txt":"只能上传一张图片，文件格式为jpg/png，文件大小不的超过500KB"},on:{"handleDelete":function($event){return _vm.handleUploadDelete(_vm.formData.infos)},"handleChange":function($event){return _vm.handleUploadChange($event)}}})],1),_c('el-form-item',[_c('el-button',{attrs:{"size":"small"},on:{"click":_vm.toBack}},[_vm._v("返回")]),_c('el-button',{attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.submitForm('dynamicValidateForm')}}},[_vm._v("提交")])],1)],2)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }