<template>
  <div class="merchant-add">
    <GlobalChunk icon="add" title="门店优势设置" :padding="[32, 0, 95, 42]">
      <el-row>
        <el-col :span="8">
          <el-form
            :model="formData"
            ref="dynamicValidateForm"
            label-width="100px"
            class="demo-ruleForm"
          >
            <!-- <el-form-item
              class="el_from"
              label="主标题："
              prop="remark"
              :rules="{
                required: true,
                message: '主标题不能为空',
                trigger: 'blur',
              }"
            >
              <div class="flex">
                <el-input
                  size="small"
                  v-model="formData.remark"
                  placeholder="请输入主标题"
                  maxlength="100"
                ></el-input>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  size="small"
                  @click="addDomain"
                  >新增</el-button
                >
              </div>
            </el-form-item> -->
            <el-form-item
              v-for="(item, index) in formData.titles"
              :key="item.key"
              :prop="'titles.' + index + '.value'"
              :rules="{
                required: true,
                message: '主标题不能为空',
                trigger: 'blur',
              }"
            >
              <span slot="label">主标题{{ index * 1 + 1 }}：</span>
              <div class="flex">
                <el-input
                  v-model="item.value"
                  placeholder="请输入主标题"
                ></el-input>
                <el-button
                  style="margin-left: 10px"
                  type="primary"
                  size="small"
                  v-if="index == 0"
                  @click="addDomain"
                  >新增</el-button
                >
                <el-button
                  v-else
                  style="margin-left: 10px"
                  type="danger"
                  size="small"
                  @click.prevent="removeDomain(item)"
                  >删除</el-button
                >
              </div>
            </el-form-item>
            <el-form-item
              class="el_from"
              label="详情上传"
              prop="infos"
              :rules="{
                required: true,
                message: '详情图片不能为空',
                trigger: 'blur',
              }"
            >
              <ElImgUpload
                :exist-image="formData.infos"
                :isBigImg="true"
                place-txt="只能上传一张图片，文件格式为jpg/png，文件大小不的超过500KB"
                @handleDelete="handleUploadDelete(formData.infos)"
                @handleChange="handleUploadChange($event)"
              />
            </el-form-item>
            <el-form-item>
              <el-button size="small" @click="toBack">返回</el-button>
              <el-button
                size="small"
                type="primary"
                @click="submitForm('dynamicValidateForm')"
                >提交</el-button
              >
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
    </GlobalChunk>
  </div>
</template>

<script>
import ElImgUpload from "../../../components/global/components/elements/upload-element";
import _api from "@/utils/request";
export default {
  name: "merchant-add",
  components: {
    ElImgUpload,
  },
  data() {
    return {
      option: [],
      DialogVisible: false,
      formData: {
        titles: [],
        infos: "",
        companyId: "",
      },
    };
  },
  created() {
    console.log(this.$route.query.companyId);
    if (this.$route.query.companyId) {
      this.formData.companyId = this.$route.query.companyId;
    }
    this.getAdvantageQuery();
  },
  methods: {
    getAdvantageQuery() {
      _api
        .advantageQuery({ companyId: this.formData.companyId })
        .then((res) => {
          this.formData.titles = [];
          if (res.data.titles.length == 0) {
            let key = Date.now();
            this.formData.titles.push({
              value: "",
              key,
              id: 1,
            });
          } else {
            res.data.titles.forEach((item, index) => {
              let key = Date.now() * 1;
              this.formData.titles.push({
                value: item,
                key: key + index,
                id: index * 1 + 1,
              });
            });
          }
          this.formData.infos = res.data.infos[0] || "";
          console.log(this.formData);
        });
    },
    removeDomain(ele) {
      this.formData.titles.forEach((item, index) => {
        if (item.key == ele.key) {
          // console.log(item.value);
          // console.log(ele.value);
          this.formData.titles.splice(index, 1);
        }
      });
    },
    addDomain() {
      this.formData.titles.push({
        value: "",
        key: Date.now(),
        id: this.formData.titles.length + 1,
      });
    },

    // 上传图片后，将图片信息保存到对应参数中
    handleUploadChange(url, key) {
      this.formData.infos = url;
    },
    //删除图片
    handleUploadDelete(key) {
      this.formData.infos = "";
    },

    // 返回上一页
    toBack() {
      this.$router.go(-1);
    },
    submitForm(formName) {
      let titles = [];
      this.formData.titles.forEach((item) => {
        titles.push(item.value);
      });
      let data = {
        titles,
        infos: [this.formData.infos],
        companyId: this.formData.companyId,
      };
      this.$refs[formName].validate((valid) => {
        if (valid) {
          _api.advantageSave(data).then((res) => {
            if (res.code === 1) {
              this.$message.success(res.msg);
              this.$router.go(-1);
            } else {
              this.$message.error(res.msg);
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
/deep/ .el-select {
  width: 100%;
}
.title_c {
  font-size: 14px !important;
  color: #989898 !important;
  font-weight: 500 !important;
  margin-left: 20px !important;
}
/deep/.global-chunk_header_filter {
  padding: 0;
}
.flex {
  display: flex;
  align-items: center;
}
.mb-20 {
  margin-bottom: 20px;
}
</style>
